let items = [
  {
    name: "PHAMARCY",
    active: false,
    subs: [
      {
        name: "Prescriptions",
        url: "/pharmacy/prescription",
        icon: "fas fa-file-prescription"
      },
      {
        name: "Patientèle",
        url: "/pharmacy/patients",
        icon: "fas fa-user-injured"
      },
      {
        name: "Médication",
        url: "/pharmacy/medication",
        icon: "fas fa-laptop-medical"
      },
      {
        name: "Posologie",
        url: "/pharmacy/posologie",
        icon: "fas fa-flask"
      }
    ]
  },
  {
    name: "ADMINISTRATION",
    active: false,
    subs: [
      {
        name: "Utilisateurs",
        url: "/pharmacy/users",
        icon: "fas fa-user-lock"
      },
      {
        name: "Configurations",
        url: "/pharmacy/setting",
        icon: "fas fa-cog"
      },
      {
        name: "Support",
        url: "/pharmacy/support",
        icon: "fas fa-info-circle"
      }
    ]
  },
  {
    name: "SECTION THREE",
    subs: [
      {
        name: "Se Deconnecter",
        function: () => {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userRole");
          localStorage.removeItem("accessToken");
          window.location.pathname = "/";
        },
        icon: "fas fa-sign-out-alt"
      }
    ]
  }
];
let items_member = [
  {
    name: "PHAMARCY",
    active: false,
    subs: [
      {
        name: "Prescriptions",
        url: "/pharmacy/prescription",
        icon: "fas fa-file-prescription"
      },
      {
        name: "Patientèle",
        url: "/pharmacy/patients",
        icon: "fas fa-user-injured"
      },
      {
        name: "Médication",
        url: "/pharmacy/medication",
        icon: "fas fa-laptop-medical"
      },
      {
        name: "Posologie",
        url: "/pharmacy/posologie",
        icon: "fas fa-flask"
      }
    ]
  },
  {
    name: "ADMINISTRATION",
    active: false,
    subs: [
      {
        name: "Configurations",
        url: "/pharmacy/setting",
        icon: "fas fa-cog"
      },
      {
        name: "Support",
        url: "/pharmacy/support",
        icon: "fas fa-info-circle"
      }
    ]
  },
  {
    name: "SECTION THREE",
    subs: [
      {
        name: "Se Deconnecter",
        function: () => {
          localStorage.removeItem("userInfo");
          localStorage.removeItem("userRole");
          localStorage.removeItem("accessToken");
          window.location.pathname = "/";
        },
        icon: "fas fa-sign-out-alt"
      }
    ]
  }
];

let all_items = {
  items: items,
  items_member: items_member
};
export default all_items;
