<template>
  <div>
    <div
      class="bg-white text-black border-0 border-b border-solid border-gray-300 px-8 fixed z-40 inset-x-0 h-71 flex items-center justify-between"
    >
      <!-- Logo -->
      <img
        @click="$router.push('/')"
        src="/logos/pharma/logo.png"
        srcset="/logos/pharma/logo@2x.png 2x, /logos/pharma/logo@3x.png 3x"
        class="h-30 mr-24 cursor-pointer"
      />
      <div
        class="hidden xl:flex xl:flex-1 items-center justify-start gap-x-6"
      ></div>
      <div class="w-full xl:w-auto flex justify-end">
        <rightInfo />
      </div>
    </div>
  </div>
</template>
<script>
import rightInfo from "./notifications/index";
export default {
  components: { rightInfo },
  data() {
    return {
      mobileActive: this.$auth.getUserInfo().isMobileVerified,
    };
  },
};
</script>
