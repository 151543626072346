<template>
  <div id="sidebar" class="group">
    <div
      class="w-50 bg-sidebar group-hover:w-210 group-hover:fixed group-hover:z-10 group-hover:shadow-sm h-screen pt-71"
    >
      <div id="links" class="flex flex-col gap-y-12 py-5">
        <ol v-for="(catLinks, indexCat) in links" :key="indexCat">
          <!-- Subs Link -->
          <ul v-if="catLinks.subs.length" class="flex flex-col">
            <li
              v-for="(subLinks, indexSub) in catLinks.subs"
              :key="indexSub"
              class="flex items-center justify-center group-hover:justify-start group-hover:px-2"
              :class="
                $route.path === subLinks.url
                  ? 'bg-dokBlue-lighter border-0 border-l-2 border-solid border-dokBlue-ultra'
                  : ''
              "
              style="height: 48px"
            >
              <template v-if="subLinks.function">
                <div
                  class="flex items-center justify-center text-blackedsidebar"
                  style="width: 20px; height: 20px"
                >
                  <i class="text-xl" :class="subLinks.icon"></i>
                </div>
                <div
                  class="text-lg hidden group-hover:block px-2 text-blackedsidebar font-EffraR cursor-pointer"
                  @click="subLinks.function"
                >
                  {{ subLinks.name }}
                </div></template
              >
              <template v-if="subLinks.url">
                <div
                  class="flex items-center justify-center"
                  :class="
                    $route.path === subLinks.url
                      ? 'text-dokBlue-ultra'
                      : 'text-blackedsidebar'
                  "
                  style="width: 20px; height: 20px"
                >
                  <i class="text-xl" :class="subLinks.icon"></i>
                </div>
                <router-link
                  :to="subLinks.url"
                  class="text-lg hidden group-hover:block px-2"
                  :class="
                    $route.path === subLinks.url
                      ? 'text-dokBlue-ultra font-EffraM'
                      : 'text-blackedsidebar font-EffraR'
                  "
                >
                  {{ subLinks.name }}
                </router-link>
              </template>
            </li>
          </ul>
        </ol>
      </div>
    </div>
  </div>
</template>
<script>
import authService from "@/auth/authService.js";
import all_items from "@/layouts/pharmacy/components/sidebar/items.js";
var key_items =
  authService.getUserRole() == "PHARMACY-MEMBER" ? "items_member" : "items";
let main = all_items[key_items];

export default {
  data() {
    return { links: all_items.items, items: main, sidebar: { expand: false } };
  },
  mounted() {},
};
</script>
